import React from 'react';
import { graphql } from 'gatsby';

import NewsPage from 'components/news/Page';

export const query = graphql`
    query($newsId: String!) {
        sanityNews(
            _id: {eq: $newsId}
        ) {
            _id
            appOnly
            slug {
                current
            }
            language {
                hreflang
            }
            image {
                defaultImage {
                    image {
                        ...ImageWithPreview
                        asset {
                            url
                        }
                    }
                    alt
                }
            }
            video {
                teaserVideoPreview {
                    image {
                        ...ImageWithPreview
                    }
                    alt
                }
                teaserVideo {
                    url
                }
            }
            _createdAt
            publishDate
            title
            category {
                _id
                ref
                title {
                    se
                    en
                }
            }
            team {
                _id
                title {
                    se
                    en
                }
                axsType
            }
            _rawContent
        }
    }
`;

const NewsItem = ({ data }) => {
    const news = data.sanityNews || null;

    if (!news) {
        return null;
    }

    return <NewsPage news={news} />;
};

export default NewsItem;
